@import url(../../App.css);

.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

h1,
.h1,
h2,
.h2,
.fw-bold {
    font-weight: 700 !important;
}

h3,
.h3,
h4,
.h4,
.fw-medium {
    font-weight: 600 !important;
}

h5,
.h5,
h6,
.h6,
.fw-semi-bold {
    font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    transition: .5s;
    font-weight: 500;
}

.btn-theme {
    background: var(--primary);
}

.btn-theme:hover {
    background: var(--light);
    color: var(--primary);
}

.btn-theme,
.btn-outline-primary:hover {
    color: white;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.fixed-top {
    transition: .5s;
}

.top-bar {
    height: 45px;
    border-bottom: 1px solid rgba(53, 94, 252, .07);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar .navbar-nav .nav-link {
    padding: 25px 15px;
    color: white;
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        margin-top: 10px;
    }

    .navbar .navbar-nav .nav-link {
        padding: 10px 0;
    }

    .offcanvas-glass {
        background: #00000095 !important;
        backdrop-filter: blur(3px);
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        visibility: hidden;
        top: 100%;
        transform: rotateX(-75deg);
        transform-origin: 0% 0%;
        transition: .5s;
        opacity: 0;
    }

    .navbar .nav-item:hover .dropdown-menu {
        transform: rotateX(0deg);
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Header ***/
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 3rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-color: var(--primary);
    border: 10px solid var(--primary);
}

.carousel-control-prev-icon {
    border-radius: 0 3rem 3rem 0;
}

.carousel-control-next-icon {
    border-radius: 3rem 0 0 3rem;
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 600px;
    }

    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.page-header {
    padding-top: 12rem;
    padding-bottom: 6rem;
    background: url(../img/header.jpg) top left no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
    font-weight: 500;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--tertiary);
}


/*** Facts ***/
.facts {
    background: linear-gradient(rgba(255, 136, 107, 0.95), rgba(252, 93, 53, 0.95)), url(../img/bg.png);
}


/*** Callback ***/
.callback {
    position: relative;
}

.callback::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(255, 136, 107, 0.95), rgba(252, 93, 53, 0.95)), url(../img/bg.png);
    /* z-index: -1; */
}


/*** Feature ***/
.feature .feature-box,
.feature .feature-box * {
    transition: .5s;
}

.feature .feature-box:hover {
    background: var(--primary);
    border-color: var(--primary) !important;
}

.feature .feature-box:hover * {
    color: #FFFFFF !important;
}


/*** Service ***/
.service .nav .nav-link {
    transition: .5s;
}

.service .nav .nav-link.active {
    border-color: var(--primary) !important;
    background: var(--primary);
}

.service .nav .nav-link.active h5 {
    color: #FFFFFF !important;
}

.service .nav .nav-link.active h5 i {
    color: #FFFFFF !important;
}


/*** Project ***/
.project-item,
.project-item .project-img {
    position: relative;
}

.project-item .project-img a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    opacity: 0;
    transition: .5s;
}

.project-item:hover .project-img a {
    opacity: 1;
}

.tech img {
    object-fit: contain;
    object-position: center;
}

.project-item .project-title {
    position: absolute;
    top: 3rem;
    right: 0;
    bottom: 0;
    left: 3rem;
    border: 1px solid var(--light);
    white-space: nowrap;
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    padding: 18px;
    z-index: -1;
    transition: .5s;
}

.project-item:hover .project-title {
    background: var(--primary);
    border-color: var(--primary);
}

.project-item .project-title h4 {
    transition: .5s;
}

.project-item:hover .project-title h4 {
    color: #FFFFFF;
}

.project-carousel .owl-nav {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.project-carousel .owl-nav .owl-prev,
.project-carousel .owl-nav .owl-next {
    margin: 0 12px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    background: var(--light);
    border-radius: 45px;
    font-size: 22px;
    transition: .5s;
}

.project-carousel .owl-nav .owl-prev:hover,
.project-carousel .owl-nav .owl-next:hover {
    background: var(--primary);
    color: var(--light);
}


/*** Team ***/
.team-item {
    position: relative;
    padding: 4rem 0;
}

.team-item img {
    position: relative;
    z-index: 2;
}

.team-item .team-text {
    position: absolute;
    top: 0;
    right: 3rem;
    bottom: 0;
    left: 3rem;
    padding: 15px;
    border: 1px solid var(--light);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: .5s;
    z-index: 1;
}

.team-item:hover .team-text {
    background: var(--primary);
    border-color: var(--primary);
}

.team-item .team-text h4 {
    transition: .5s;
}

.team-item:hover .team-text h4 {
    color: #FFFFFF;
}

.team-item .team-social .btn {
    background: var(--light);
    color: var(--primary);
}

.team-item:hover .team-social .btn {
    background: #FFFFFF;
}

.team-item .team-social .btn:hover {
    background: var(--primary);
    color: var(--light);
}


/*** Testimonial ***/
.testimonial-item {
    position: relative;
    text-align: center;
    padding-top: 30px;
}

.testimonial-item .testimonial-text {
    position: relative;
    text-align: center;
}

.testimonial-item .testimonial-text .btn-square {
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
}

.testimonial-item .testimonial-text::before {
    position: absolute;
    content: "";
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    border: 30px solid;
    border-color: var(--light) transparent transparent transparent;
}

.testimonial-item .testimonial-text::after {
    position: absolute;
    content: "";
    bottom: -59px;
    left: 50%;
    transform: translateX(-50%);
    border: 30px solid;
    border-color: #FFFFFF transparent transparent transparent;
}

.testimonial-carousel .owl-item img {
    margin: 0 auto;
    width: 100px;
    height: 100px;
}

.testimonial-carousel .owl-dots {
    margin-top: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--light);
    border-radius: 30px;
    transition: .5s;
}

.testimonial-carousel .owl-dot::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    top: 6px;
    left: 6px;
    border-radius: 16px;
    background: var(--light);
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    border-color: var(--primary);
}

.testimonial-carousel .owl-dot.active::after {
    background: var(--primary);
}


/*** Footer ***/
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--light);
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.copyright {
    color: var(--light);
    background: black;
}

.copyright a {
    color: var(--light);
}

.copyright a:hover {
    color: var(--primary);
}

/* ---------testimonial--------------- */

.testimonial {
    padding: 100px 0;
}

.testimonial .row .tabs {
    all: unset;
    display: grid;
    row-gap: 40px;
    justify-content: start;
    grid-template-columns: 1fr 1fr 1fr;
}

.testimonial .row .tabs li {
    all: unset;
    display: block;
}

.tabs .active img {
    border: 4px solid #b35555 !important;
    padding: 7px;
    transform: scale(1.25);
}

.tabs li div img {
    position: relative;
    z-index: 2;
    cursor: pointer;

}

.tabs li div {
    position: relative;
}

.tabs .active div::after,
.tabs .active div::before {
    opacity: 1;
    transform: translateY(0px);
}

.tabs li div::after {
    content: "";
    width: 50px;
    border-radius: 50%;
    height: 50px;
    /* margin-top: -50px; */
    left: 0px;
    top: -40px;
    transform: translateX(-100px);
    z-index: 1;
    opacity: 0;
    transition: all 0.5s;
    background-color: #9b2222;
    filter: blur(5px);
    position: absolute;
}

.swiper-slide {
    padding: 20px;
}

.bg-header {
    background-color: #F5F5F5;
    z-index: 99999;
}

.tabs li div::before {
    content: "";
    width: 100px;
    transform: translateY(100px);
    border-radius: 50%;
    height: 100px;
    /* margin-top: -50px; */
    right: 0;
    bottom: -30px;
    z-index: 2;
    opacity: 0;
    transition: all 0.5s;
    background-color: #9b2222;
    /* filter: blur(3px); */
    animation: fly 2s infinite ease-in-out;
    position: absolute;
}

.tabs li div img:hover {
    transition: all 0.5s;
    border: 4px solid #9b2222 !important;
    padding: 7px;
}

.tabs>li>.text-center>img {
    border: 5px solid #b66e66;
}

.testimonial .row .carousel>h3 {
    font-size: 20px;
    line-height: 1.45;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    margin-bottom: 0;
}

.testimonial .row .carousel h1 {
    font-size: 40px;
    line-height: 1.225;
    margin-top: 23px;
    font-weight: 700;
    margin-bottom: 0;
}

.testimonial .row .carousel .carousel-indicators {
    all: unset;
    padding-top: 43px;
    display: flex;
    gap: 10px;
    list-style: none;
}

.testimonial .row .carousel .carousel-indicators li {
    background: #000;
    background-clip: padding-box;
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper {
    margin-top: 42px;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper p {
    font-size: 18px;
    line-height: 1.72222;
    font-weight: 500;
    color: rgba(0, 0, 0, .7);
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper h3 {
    color: #000;
    font-weight: 700;
    margin-top: 37px;
    font-size: 20px;
    line-height: 1.45;
    text-transform: uppercase;
}

.hover-sub:hover {
    transform: scale(1.05);
    transition: 0.5s;
    cursor: pointer;
}

.hover-sub:active {
    transform: scale(0.95);
    transition: 0.5s;
    cursor: pointer;
}

.text-elaichi {
    color: #2b9226 !important;
}

@media only screen and (max-width: 1250px) {
    .testimonial .row .tabs {
        all: unset;
        display: grid;
        row-gap: 30px;
        justify-content: center;
        grid-template-columns: 1fr 1fr;
    }
}